import React, { FC, useState, useMemo } from 'react'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import Papa from 'papaparse'
import { useSnackbar } from 'notistack'
import z from 'zod'

import { CustomFile } from '../UI/FileUploadArea'
import FileUploader from '../UI/FileUploader'
import UploadedFilesList from './UploadedFilesList'

import { str, namesCheck, phonesCheck } from '../../assets/constans/formValidation'

const userFileSchema = z.object({
  name: namesCheck,
  surname: namesCheck.or(str.length(0)),
  email: str.email('invalid email'),
  mobile: phonesCheck.or(str.length(0)),
  phone: phonesCheck.or(str.length(0)),
  admin: z.string(),
  nfc: z.string()
})
const transform = (arg: string) => arg.toLowerCase() === 'true'

export type UserFileSchema = ReturnType<typeof userFileSchema['parse']>
export type UserFile = Omit<UserFileSchema, 'admin' | 'nfc'> & {
  admin: boolean
  nfc: boolean
  errors?: Errors
}
export type BulkUserPayload = Omit<UserFile, 'errors'>

type Props = {
  onSave: (data: { payload: BulkUserPayload[]; customer: string }) => void
}
type Errors = { [K in keyof Partial<UserFileSchema>]: string }

const UserFileUploader: FC<Props> = ({ onSave }) => {
  const [open, setOpen] = useState(false)
  const [list, setList] = useState<UserFile[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const onFileSave = (file: CustomFile) => {
    setOpen(true)
    const newFile = new File([file.arrayBuffer!], file.name)

    Papa.parse(newFile, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: ({ errors, data }, res) => {
        if (errors.length) {
          enqueueSnackbar(errors[0].message, {
            variant: 'error'
          })
          return closeList()
        }
        const newList: UserFile[] = []

        data.forEach((user: any) => {
          const validUser = userFileSchema.safeParse(user)
          if (validUser.success) {
            const list = {
              ...validUser.data,
              admin: transform(validUser.data.admin),
              nfc: transform(validUser.data.nfc)
            }
            newList.push(list)
          } else {
            let errorUser: Errors = {}
            validUser.error.issues.forEach(
              ({ message, path }) => (errorUser = { ...errorUser, [path[0]]: `${message}` })
            )
            newList.push({ ...user, errors: errorUser })
          }
        })
        const existingEmail: string[] = []
        const checkedEmailList = newList.map((item) => {
          let err = ''
          if (item.nfc && item.email.replace(/(@.+)/, '').length < 14) {
            err = 'NFC ID in email has to contain at least 14 charachters'
          }
          if (!item.email.length) {
            err = 'email is required field'
          }

          if (item.email && existingEmail.includes(item.email)) {
            err = 'has to be unique'
          }
          item.email && existingEmail.push(item.email)
          return err ? { ...item, email: '', errors: { ...item.errors, email: err } } : item
        })
        const checkedNfc = checkedEmailList.map((item) => {
          const err = item.errors?.email && 'invalid NFC ID'
          return err
            ? {
                ...item,
                errors: { ...item.errors, nfc: err }
              }
            : item
        })

        const checkedAdminNfcList = checkedNfc.map((item) => {
          if (item.admin && item.nfc) {
            return {
              ...item,
              errors: { ...item.errors, admin: 'admin should not contain NFC ID' }
            }
          }
          return item
        })
        setList(checkedAdminNfcList)
      }
    })
  }

  const validUsers: BulkUserPayload[] = useMemo(() => {
    return list.filter((item) => !item.errors)
  }, [list])

  const onUpload = (customer: string) => {
    onSave({ payload: validUsers, customer })
    closeList()
  }

  const closeList = () => {
    setList([])
    setOpen(false)
  }

  return (
    <>
      <FileUploader
        title='Upload file with users'
        accept={{ 'text/csv': ['.csv'] }}
        onSave={onFileSave}
      >
        <Button variant='outlined' startIcon={<CloudUploadIcon />} sx={{ marginRight: '8px' }}>
          Upload file with users
        </Button>
      </FileUploader>
      <UploadedFilesList open={open} handleClose={closeList} list={list} onUpload={onUpload} />
    </>
  )
}

export default UserFileUploader
